<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="page terms">
    <section class="banner">
      <div class="container">
        <h2 v-text="$t('Terms and conditions')"></h2>
      </div>
    </section>
    <section class="page__content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ol>
              <li>
                <h3 v-text="$t('Introduction (title)')"></h3>
                <div v-html="$t('Introduction (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Use of our service (title)')"></h3>
                <div v-html="$t('Use of our service (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('License to use website (title)')"></h3>
                <div v-html="$t('License to use website (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('User content (title)')"></h3>
                <div v-html="$t('User content (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('License Grant (title)')"></h3>
                <div v-html="$t('License Grant (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Proprietary Rights (title)')"></h3>
                <div v-html="$t('Proprietary Rights (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Eligibility (title)')"></h3>
                <div v-html="$t('Eligibility (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Security (title)')"></h3>
                <div v-html="$t('Security (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('No warranties (title)')"></h3>
                <div v-html="$t('No warranties (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Limitations of liability (title)')"></h3>
                <div
                  v-html="$t('Limitations of liability (description)')"
                ></div>
              </li>
              <li>
                <h3 v-text="$t('Exceptions (title)')"></h3>
                <div v-html="$t('Exceptions (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Reasonableness (title)')"></h3>
                <div v-html="$t('Reasonableness (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Other parties (title)')"></h3>
                <div v-html="$t('Other parties (description)')"></div>
              </li>
              <li>
                <h3 v-text="$t('Indemnity (title)')"></h3>
                <div v-html="$t('Indemnity (description)')"></div>
              </li>
              <li>
                <h3
                  v-text="$t('Breaches of these terms and conditions (title)')"
                ></h3>
                <div
                  v-html="
                    $t('Breaches of these terms and conditions (description)')
                  "
                ></div>
              </li>
              <li>
                <h3 v-text="$t('Severability (title)')"></h3>
                <div v-html="$t('Severability (description)')"></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './terms.scss';
</style>
